<template>
  <el-tooltip popper-class="el-tooltip" :visible-arrow="false" effect="dark" v-delTabIndex :content="$t('search_misjudgment')/*誤判*/" placement="bottom">
    <div class="mis-judgment" :class="{ error: event.misjudged }" @click.stop="setEventMisjudged(event)">
      <img v-if="event.misjudged === 0" src="@/assets/icons/misjudged-disable.svg" alt="">
      <img v-else src="@/assets/icons/misjudged-yellow.svg" alt="">
    </div>
  </el-tooltip>
</template>

<script>
import { apiEditBoviaLprEvent, apiEditBoviaFrEvent } from '@/api/index.js'

export default {
  name: 'EventMisjudgment',
  props: {
    event: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      api: this.type === 'lpr' ? apiEditBoviaLprEvent : apiEditBoviaFrEvent
    }
  },
  methods: {
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await this.api(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.mis-judgment {
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.2;
  &.error {
    opacity: 1;
  }
}

img {
  width: 100%;
  height: 100%;
  vertical-align: baseline;
}
</style>